/** Colors **/
$primary: #07948d;
$secondary: #9cc5c3;
$accent: #fbc700;

html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh; // Set max-height to fix wrong calculations
  overflow-x: hidden !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  display: none; /* make scrollbar transparent */
}

#root {
  height: 100%;
}

/** Navigation **/
header {
  height: 6%;
}
.navbar {
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  &__list {
    padding-left: 0;
    display: flex;
    flex-direction: row;

    &__item {
      list-style: none;
      width: 70vw;
      text-align: center;
      color: white;
      font-size: 1.5rem;
    }
  }
}

.sidebar {
  position: absolute;
  float: left;
  height: 100%;
  width: 80%;
  background-color: #07948d;
  transition: 1s;
  left: -85%;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.7));
}

.showSidebar {
  transition: 1s;
  left: 0;
}

.sidebar__list {
  list-style: none;
  margin: 0;
  height: 100%;

  &__logout {
    position: relative;
    top: 70%;
  }

  li {
    padding: 10px;

    a {
      font-size: 1.2rem;
      text-decoration: none;
      color: white;
    }
  }
}

/** Containers **/
.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 94%;
  max-height: 94%;
  height: auto;
  min-width: 100vw;
  background: linear-gradient(
      0deg,
      rgba(251, 151, 0, 0.1),
      rgba(251, 151, 0, 0.1)
    ),
    url("ccbackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;


  img {
    max-height: 150px;
    object-fit: contain;
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 96%;
  max-height: 96%;
  min-width: 100vw;
  overflow-y: auto !important;
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__filler {
    min-width: 100vw;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.grid {
  display: grid;
  justify-self: flex-start;
  grid-template-columns: 1fr 1fr;

  &__element {
    margin: 10px;
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    max-width: 350px;
    max-height: 350px;
    height: 40vw;
    width: 40vw;

    span {
      padding: 10px;
    }
  }
}

.overlay {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 1;

  &__modal {
    z-index: 5;
    border-radius: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 750px;
    min-height: 20vh;
    margin: auto;

    &__header {
      display: inline-block;

      padding: 20px 0px;
      width: 100%;
      font-size: 1.2rem;

      &__close {
        position: absolute;
        margin-left: 15px;
        font-size: 1.5rem;
      }

      &__title {
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.modalButtonContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/** Inputs **/
.input {
  width: 50vw;
  padding: 15px 20px;
  margin: 0px 5px;
  border-radius: 10px;
  font-size: 1rem;
  border: 1px solid lightgray;
  color: gray;
  max-width: 80%;
}

.textArea {
  width: 50vw;
  padding: 15px 20px;
  margin: 0px 5px;
  border-radius: 10px;
  font-size: 1rem;
  border: 1px solid lightgray;
  color: gray;
  max-width: 80%;
}

.button {
  width: calc(50vw + 40px);
  max-width: calc(80% + 40px);
  padding: 15px 20px;
  margin: 10px;
  background-color: $accent;
  font-size: 1rem;
  border: 0.2px solid lightgray;
  border-radius: 50px;
  color: white;
  text-align: center;
  text-decoration: none;

  &__primary {
    @extend .button;
    background-color: $primary;

    i:nth-child(1) {
      float: left;
    }
    i:nth-child(2) {
      float: right;
    }
  }
}

.wasteTypeButton {
  width: calc(50vw + 40px);
  max-width: calc(650px);
  padding: 15px 20px;
  margin: 2px;
  background-color: #f2f2f2;
  font-size: 1rem;
  border: 0.2px solid $primary;
  border-radius: 10px;
  color: black;
  text-align: center;
  text-decoration: none;

  &__active {
    @extend .wasteTypeButton;
    background-color: $primary;
    color: white;
  }
}

.textBlock {
  margin: 20px 10vw;
  text-align: center;
}

.line {
  color: $primary;
  width: 75%;
}

/** FAQ */
.faq {
  list-style: none;
  width: 100%;
  padding: 0;
  &__item {
    padding: 20px;
    &__question {
      font-weight: 700;
      font-size: 1.2rem;
      color: white;
    }

    &__answer {
      font-size: 1.1rem;
      color: white;
      display: none;
    }
  }
}

.answer__active {
  display: block !important;
}
